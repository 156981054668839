import { ReactNode, createContext, useState, useContext, useEffect, useCallback } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useRouter } from 'next/router';
import { firebaseApp } from 'src/lib/firebase/firebase';

const AUTH_PAGES = ['/account', '/onboarding'];

export type AuthContextProps = {
  uid: string | null;
  loading: boolean;
  signOut: () => Promise<void>;
};

export type AuthProps = {
  children: ReactNode;
};

const AuthContext = createContext<Partial<AuthContextProps>>({});

export const useAuthContext = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }: AuthProps) => {
  const router = useRouter();
  const auth = getAuth(firebaseApp);
  const [uid, setUid] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      const newUid = user?.uid ?? null;

      setUid(newUid);
      setLoading(false);

      if (!newUid && AUTH_PAGES.includes(router.pathname)) {
        await router.push('/login');
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const signOut = useCallback(async () => {
    await auth.signOut();
    await router.push('/login');
  }, []);

  return <AuthContext.Provider value={{ uid, loading, signOut }}>{children}</AuthContext.Provider>;
};
