import React, { ButtonHTMLAttributes, DetailedHTMLProps, FCwC } from 'react';
import { DeepReadonly } from 'utility-types';

type Props = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> &
  DeepReadonly<{
    size?: 'lg' | 'md' | 'sm';
  }>;

const Component: FCwC<Props> = ({ children, className, size = 'md', ...others }) => (
  <button
    {...others}
    className={`inline-block bg-indigo-500 hover:bg-indigo-600 active:bg-indigo-700 focus-visible:ring ring-indigo-300 text-white text-sm md:text-base font-semibold text-center rounded outline-none transition duration-100 px-4 md:px-8 py-3 whitespace-nowrap disabled:bg-gray-300 ${className}`}
  >
    {children}
  </button>
);

export const AppButton = Component;
