import React, { FCwC } from 'react';
import Header from './header';
import Footer from './footer';

const Component: FCwC = (props) => (
  <>
    <Header />
    {props.children}
    <Footer />
  </>
);

export default Component;
