import dynamic from 'next/dynamic';
import React, { FC, Suspense } from 'react';
import Logo from './logo';

const Nav = dynamic(() => import('./nav'));

const Component: FC = () => (
  <header className='z-20 sticky h-16 top-0 px-4 sm:px-6 md:px-12 xl:px-16 py-3 shadow-md bg-white bg-opacity-70 text-slate-700 backdrop-blur-md'>
    <div className='max-w-screen-2xl m-auto flex justify-between items-center gap-4'>
      <Logo />
      <Suspense fallback={null}>
        <Nav />
      </Suspense>
    </div>
  </header>
);

export default Component;
