export const WEB_APP = {
  name: {
    default: 'kula',
    ja: 'クウラ',
  },
  company: 'Nuts App',
  catchphrase: 'kintoneでカンタン ウェブメディア',
  desctiption:
    'ウェブサイトの設立、メンテナンス、SEO施策… ウェブメディアに必要な様々な要素を、kintoneだけで解決するウェブアプリです。',
  url: 'https://kula.konomi.app',

  author: {
    name: 'Ribbit',
    description: '税理士法人のエンジニア',
    url: 'https://icu.konomi.app',
  },

  slug: {
    article: 'articles',
  },

  featureArticlePath: '/articles/7sZMlHqrP8X4HaVjFLE9CjmWPZP2/197/1',
  sampleArticlePath: '/articles/5DgBJ94JfuR77rTfrvVz7OdmoLz1/200/1',

  social: {
    twitter: 'LbRibbit',
    github: 'local-bias',
    zenn: 'ribbit',
    blog: 'https://ribbit.konomi.app',
  },
};

export const IMAGE_CATEGORIES: { label: string; code: rtdb.ImageCategory }[] = [
  { label: 'PC', code: 'pc' },
  { label: '動物', code: 'animal' },
  { label: '風景', code: 'landscape' },
];

export const IMAGE_SET_COUNT: Record<rtdb.ImageCategory, number> = {
  pc: 7,
  landscape: 4,
  animal: 9,
};
