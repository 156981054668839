import React, { FC } from 'react';
import { WEB_APP } from 'src/static/app';

const Component: FC = () => {
  return (
    <div className='basis-[300px]'>
      <ul className='flex flex-initial flex-col md:flex-1'>
        <li className='py-3 md:py-0 md:pb-4'>
          <p className='font-bold'>Categories</p>
        </li>
        <li className='py-3 md:py-0 md:pb-4'>
          <a href={`${WEB_APP.featureArticlePath}`} className='flex items-center gap-2'>
            kulaとは
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Component;
