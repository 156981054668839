import { atom } from 'recoil';
import { AppViewType } from 'src/components/pages/account/app';
import { FormCategoryCode } from 'src/components/pages/account/index/sidebar';
import { WEB_APP } from 'src/static/app';

export const pagePropsState = atom<any>({
  key: 'pagePropsState',
  default: null,
});

export const displayedViewState = atom<FormCategoryCode>({
  key: 'displayedViewState',
  default: 'linkApps',
});

export const displayedAppViewTypeState = atom<AppViewType>({
  key: 'displayedAppViewTypeState',
  default: 'basic',
});

export const headerTitleState = atom<string>({
  key: 'headerTitleState',
  default: WEB_APP.name.default,
});

export const headerLogoURLState = atom<string>({
  key: 'headerLogoURLState',
  default: '/icon/icon-96x96.png',
});

export const headerHrefState = atom<string>({
  key: 'headerHrefState',
  default: '/',
});

export const articlesState = atom<webapp.Article[]>({
  key: 'articlesState',
  default: [],
});

export const userAppsState = atom<webapp.UserApps>({
  key: 'userAppsState',
  default: {},
});
