import React, { FC, memo } from 'react';
import { useRecoilValue } from 'recoil';
import { GithubIcon } from 'src/components/ui/icon/github';
import { TwitterIcon } from 'src/components/ui/icon/twitter';
import { ZennIcon } from 'src/components/ui/icon/zenn';
import { pagePropsState } from 'src/states/app';
import { WEB_APP } from 'src/static/app';

const Component: FC = () => {
  const pageProps = useRecoilValue(pagePropsState);

  const headerTitle = pageProps?.header?.title;

  if (headerTitle) {
    return null;
  }

  return (
    <div>
      <div className='mb-4'>Follow Us</div>
      <div className='flex gap-4'>
        <a
          href={`https://twitter.com/${WEB_APP.social.twitter}`}
          target='_blank'
          rel='noreferrer'
          className='text-slate-300 hover:text-slate-200 active:text-slate-200 transition duration-100'
          title='運営者のツイッター'
        >
          <TwitterIcon width={20} height={20} />
        </a>

        <a
          href={`https://github.com/${WEB_APP.social.github}`}
          className='text-slate-300 hover:text-slate-200 active:text-slate-200 transition duration-100'
          title='運営者のGithub'
          target='_blank'
          rel='noreferrer'
        >
          <GithubIcon width={20} height={20} />
        </a>
        <a
          href={`https://zenn.dev/${WEB_APP.social.zenn}`}
          className='text-slate-300 hover:text-slate-200 active:text-slate-200 transition duration-100'
          title='運営者のZenn'
          target='_blank'
          rel='noreferrer'
        >
          <ZennIcon width={20} height={20} />
        </a>
      </div>
    </div>
  );
};

export default memo(Component);
