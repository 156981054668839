import Image from 'next/image';
import Link from 'next/link';
import React, { FC } from 'react';
import { useRecoilValue } from 'recoil';
import { pagePropsState } from 'src/states/app';
import { WEB_APP } from 'src/static/app';
import { DeepReadonly } from 'utility-types';

type ContainerProps = DeepReadonly<{}>;
type Props = ContainerProps & DeepReadonly<{}>;

const Component: FC<Props> = () => {
  const pageProps = useRecoilValue(pagePropsState);

  const headerTitle = pageProps?.header?.title || WEB_APP.name.default;
  const headerLogoURL = pageProps?.header?.logoURL || '/icon/icon-96x96.webp';
  const headerHref = pageProps?.header?.href || '/';

  return (
    <div className='logo'>
      <Link href={headerHref}>
        <a className='flex items-center gap-4'>
          <img
            className='w-auto h-10'
            loading='lazy'
            width={60}
            height={60}
            src={headerLogoURL}
            alt='サイトロゴ'
          />
          <div>
            <span className='text-xl font-bold'>{headerTitle}</span>
          </div>
        </a>
      </Link>
    </div>
  );
};

const Container: FC<ContainerProps> = (props) => {
  return <Component {...props} />;
};

export default Container;
