import Link from 'next/link';
import { FC } from 'react';
import { WEB_APP } from 'src/static/app';

import Categories from './categories';
import Logo from './logo';
import Social from './social';

const Bottom: FC = () => (
  <div className='flex justify-between mt-20'>
    <div className='text-xs text-slate-400'>
      powered by{' '}
      <Link href='/'>
        <a className='cursor-pointer'>kula</a>
      </Link>{' '}
      &copy; {new Date().getFullYear()} {WEB_APP.company}
    </div>
    <div className='flex gap-6'>
      <a
        href='https://policies.konomi.app/privacy'
        target='_blank'
        rel='noreferrer'
        className='flex items-center gap-2'
      >
        プライバシーポリシー
      </a>
      <a
        href='https://policies.konomi.app/terms'
        target='_blank'
        rel='noreferrer'
        className='flex items-center gap-2'
      >
        利用規約
      </a>
    </div>
  </div>
);

const Component: FC = () => (
  <footer className='border-t text-sm bg-slate-800 text-slate-300'>
    <div className='max-w-screen-xl mx-auto py-12 lg:pt-24 lg:pb-12 px-3'>
      <div className='flex flex-wrap justify-between gap-6'>
        <div className='basis-[300px]'>
          <Logo />
          <Social />
        </div>
        <div className='flex flex-wrap basis-[600px]'>
          <Categories />
        </div>
      </div>

      <Bottom />
    </div>
  </footer>
);

export default Component;
