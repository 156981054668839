import { ErrorBoundary } from '@sentry/nextjs';
import { FallbackRender } from '@sentry/react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { FC, FCwC, useCallback } from 'react';
import { AppButton } from '../ui/app-button';

const Component: FC<Parameters<FallbackRender>[0]> = ({ resetError }) => {
  const router = useRouter();

  const onClick = useCallback(() => {
    resetError();
    router.reload();
  }, []);

  return (
    <div className='w-screen h-screen flex justify-center items-center bg-white'>
      <div className='px-4 flex flex-col gap-4 flex-wrap justify-center items-center'>
        <h1 className='text-xl my-2'>画面描画時にエラーが発生しました</h1>
        <Image src='/img/crash-blue.webp' alt='墜落したロケット' width={400} height={400} />
        <div>
          <p>予期しないエラーが発生しました。</p>
          <p>リトライしても解決しない場合は、運営者までお問い合わせください。</p>
        </div>
        <AppButton onClick={onClick}>リトライ</AppButton>
      </div>
    </div>
  );
};

const Container: FCwC = ({ children }) => (
  <ErrorBoundary fallback={(props) => <Component {...props} />}>{children}</ErrorBoundary>
);

/**
 * コンポーネントをラップすることで、エラー発生時に画面が真っ白になってしまうことを防ぎ、エラーをユーザーへ通知、リトライすることができます
 */
export const AppErrorBoundary = Container;
