import Link from 'next/link';
import React, { FC, memo } from 'react';
import { useRecoilValue } from 'recoil';
import { pagePropsState } from 'src/states/app';
import { WEB_APP } from 'src/static/app';

const Component: FC = () => {
  const pageProps = useRecoilValue(pagePropsState);

  const headerTitle = pageProps?.header?.title || WEB_APP.name.default;
  const headerLogoURL = pageProps?.header?.logoURL || '/icon/icon-96x96.webp';
  const headerHref = pageProps?.header?.href || '/';

  return (
    <Link href={headerHref}>
      <a className='flex items-center font-bold mb-12'>
        <img
          className='w-auto h-10 mr-2'
          loading='lazy'
          width={60}
          height={60}
          src={headerLogoURL}
          alt='サイトロゴ'
        />
        <span className='text-base'>{headerTitle}</span>
      </a>
    </Link>
  );
};

export default memo(Component);
