import { FC, useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import { pagePropsState } from 'src/states/app';

const Component: FC<{ pageProps: any }> = ({ pageProps }) => {
  const setProps = useSetRecoilState(pagePropsState);

  useEffect(() => {
    setProps(pageProps);
  }, [pageProps]);

  return null;
};

export default Component;
