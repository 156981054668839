import type { AppProps } from 'next/app';
import { DefaultSeo } from 'next-seo';
import { RecoilRoot } from 'recoil';
import Layout from 'src/components/layout';
import { WEB_APP } from 'src/static/app';
import { GoogleTagManager } from 'src/components/functional/google-tag-manager';

import 'src/styles/reset.css';
import 'src/styles/global.scss';
import 'src/styles/highlight.css';
import 'tailwindcss/tailwind.css';
import { AppErrorBoundary } from 'src/components/functional/error-boundary';
import { AuthProvider } from 'src/providers/auth';
import { pagePropsState } from 'src/states/app';
import PagePropsObserver from 'src/components/functional/page-props-ovserver';

const App = ({ Component, pageProps }: AppProps) => {
  process?.env?.NODE_ENV === 'development' && console.log('🐘 initial props', pageProps);

  return (
    <RecoilRoot
      initializeState={({ set }) => {
        set(pagePropsState, pageProps);
      }}
    >
      <AppErrorBoundary>
        <DefaultSeo title={WEB_APP.name.default} description={WEB_APP.catchphrase} />
        <PagePropsObserver pageProps={pageProps} />
        <GoogleTagManager />
        <AuthProvider>
          <Layout>
            <Component {...pageProps} />
          </Layout>
        </AuthProvider>
      </AppErrorBoundary>
    </RecoilRoot>
  );
};

export default App;
